<template>
    <div class="geofences">
        <div class="map-wrapper">
            <div id="map"></div>
            <MapIcons :map="map" :data="iconsData"></MapIcons>

            <div class="search align-center">
                <v-text-field v-model="address" label="Адрес доставки" outlined filled hide-details v-on:keyup.enter="onClickSearch"></v-text-field>
                <v-btn color="blue" class="ml-2 search-button" dark bottom @click="onClickSearch()">
                    <v-icon>mdi-magnify</v-icon>
                    <!-- <v-icon>mdi-magnify</v-icon> Найти -->
                </v-btn>
            </div>

            <v-alert type="warning" class="alert elevation-3" :value="needSave" :dismissible="true">
                {{ $t('geofence.need_save_geofences') }}
            </v-alert>
            
        </div>

        <v-fab-transition v-if="!userIsPoint">
            <v-btn v-show="true" color="blue" dark absolute left bottom fab style="bottom: 15px" :disabled="!needSave" @click="onClickSave()">
                <v-icon>mdi-content-save-outline</v-icon>
            </v-btn>
        </v-fab-transition>

        <v-banner v-if="geofenceSelected != null" single-line :sticky="sticky" class="banner" :elevation="5">
            <v-form>
                <v-container>
                    <v-row>
                        <v-btn class="button-close" text tile @click="onClickClose()"><v-icon small>mdi-close</v-icon></v-btn>

                        <v-col cols="12">
                            <v-text-field v-model="geofence.name" label="Название геозоны" placeholder="Например, Вторая речка" @change="onNameSelect()" :readonly="userIsPoint" hide-details></v-text-field>
                        </v-col>

                        <v-col cols="12" v-if="!userIsPoint">
                            <div class="colors_wrapper">
                                <div class="header">Цвет</div>
                                <div v-for="(color, index) in colors" :key="index" :style="{ 'background-color': color, width: colorWidth + 'px' }" :class="index == geofence.color ? 'selected' : ''" @click="onColorSelect(index)" class="color"></div>
                            </div>
                        </v-col>

                        <v-row v-if="!userIsPoint" class="mx-0">
                            <v-col cols="12">
                                <p class="my-0">Цена доставки, руб.</p>
                            </v-col>

                            <v-col cols="4">
                                <v-text-field v-model="geofence.price[0]" label="Цена 1" @change="onPriceSelect()" hide-details></v-text-field>
                            </v-col>

                            <v-col cols="4">
                                <v-text-field v-model="geofence.price[1]" label="Цена 2" @change="onPriceSelect()" hide-details></v-text-field>
                            </v-col>

                            <v-col cols="4">
                                <v-text-field v-model="geofence.price[2]" label="Цена 3" @change="onPriceSelect()" hide-details></v-text-field>
                            </v-col>

                            <v-col cols="4">
                                <v-text-field v-model="geofence.price[3]" label="Цена 4" @change="onPriceSelect()" hide-details></v-text-field>
                            </v-col>

                            <v-col cols="4">
                                <v-text-field v-model="geofence.price[4]" label="Цена 5" @change="onPriceSelect()" hide-details></v-text-field>
                            </v-col>
                        </v-row>

                        <div v-if="userIsPoint">
                            <v-col cols="12">
                                <v-text-field v-model="pointPrice" label="Стоимость доставки" :readonly="userIsPoint" hide-details></v-text-field>
                            </v-col>
                        </div>
                        
                        <v-col cols="12" v-if="!userIsPoint">
                            <p class="my-0 text-wrap">
                                <v-icon color=warning class="mr-1">mdi-information-outline</v-icon>
                                Не забудьте назначить цены для точек в <router-link :to="{ name: 'settings' }"><b>Настройках</b></router-link>
                            </p>
                        </v-col>

                        <v-col cols="12" v-if="!userIsPoint || (geofence.descr && geofence.descr.length)">
                            <v-text-field v-model="geofence.descr" label="Описание" @change="onDescrSelect()" :readonly="userIsPoint" hide-details></v-text-field>
                        </v-col>

                        <!-- <v-col cols="12">
                            <v-switch v-model="geofence.notify" class="ma-2" label="Уведомлять при входе/выходе" @click="onNotifySelect()"></v-switch>
                        </v-col> -->

                    </v-row>
                </v-container>
            </v-form>
        </v-banner>
    </div>
</template>

<script>
    import MapIcons from '../logistic/MapIcons'

    import config from '../../config'
    import moment from 'moment-timezone'


    moment.locale('ru')

    import * as L from 'leaflet'
    import 'leaflet/dist/leaflet.css'

    import '@geoman-io/leaflet-geoman-free'
    import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'
    

    import { v4 as uuidv4 } from 'uuid'

    const cfg = require('../../config')

    // метод для копирования и слияния - полезный!
    // var obj = { a: 1 };
    // var copy = Object.assign({}, obj);

    /* leaflet icon */
    delete L.Icon.Default.prototype._getIconUrl
    L.Icon.Default.mergeOptions({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    })

    export default {
        data() {
            return {
                sticky: false,
                needSave: false,
                map: null,
                geofences: {},
                styleInit: {
                    fillOpacity: 0.3,
                    opacity: 0.6,
                    weight: 1.0,
                    color: 'blue',
                },
                styleHighlight: {
                    fillOpacity: 0.4,
                    opacity: 0.8,
                    weight: 1.7,
                    color: 'blue',
                },
                styleSelect: {
                    fillOpacity: 0.4,
                    opacity: 0.8,
                    weight: 3,
                    color: 'blue',
                },
                geofenceDraw: false,
                geofenceOver: false,
                geofenceDrag: false,
                markerDrag: false,
                geofenceSelected: null,
                colors: [],
                colorWidth: 0,
                geofence: {
                    id: 0,
                    color: 0,
                    name: '',
                    notify: false,
                    price: [],
                    descr: '',
                },
                geofenceSaved: true,
                geofenceSaveTimer: 0,
                timer: null,
                sat: cfg.geofence.color.saturation,
                light: cfg.geofence.color.lightness,
                userIsPoint: false,
                pointPriceIndex: 0,
                pointPrice: 0,
                address: '',
                iconsData: [],
            }
        },
        components: {
            MapIcons,
        },
        mounted() {
            console.log('geofence mounted!')

            this.initData()

            this.timer = setInterval(() => {
                if (this.geofenceSaveTimer) {
                    this.geofenceSaveTimer--
                }
                if (!this.geofenceSaved && this.geofenceSaveTimer == 0) {
                    this.geofenceSaved = true
                    // emit event
                    // что делает эта штука?
                    this.$emit('change', this.getDataAsGeoJSON())
                    console.log('⚡️ save emit')
                    // что делает эта штука?
                    
                    
                    this.geofences = this.getDataAsGeoJSON()
                }
            }, 100)
        },
        created() {
            console.log('geofence created!')
            this.userIsPoint = this.$store.state.user.role != config.user.role.operator
            this.pointPriceIndex = -1

            if (this.userIsPoint) {
                this.$store
                    .dispatch('user_get', this.$store.state.user.operator_tid)
                    .then(res => {
                        if (res.data.success) {

                            let settings
                            if (res.data.user.settings !== null && typeof res.data.user.settings === 'object') {
                                settings = res.data.user.settings
                            } else {
                                settings = JSON.parse(res.data.user.settings)
                            }
                            console.log('settings', settings)

                            // pointPrice = settings.price_courier_default

                            this.pointPriceIndex = 0
                            let find = false
                            for (let price_block of settings.prices) {
                                for (let point_id of price_block) {
                                    if (point_id == this.$store.state.user.point_id) {
                                        find = true
                                        break
                                    }
                                }
                                if (find) break
                                this.pointPriceIndex++
                            }
                            console.log('this.pointPriceIndex', this.pointPriceIndex)
                            console.log('find', find)
                            if (!find) {
                                this.pointPriceIndex = -1
                            }

                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
        },
        destroyed() {
            return this.timer ? clearInterval(this.timer) : false
        },
        methods: {
            onClickClose() {
                this.modeDeselect()
            },

            onClickSearch() {
                if (!this.address || this.address == '') {
                    this.$notify.toast(`Заполните адрес для поиска.`, {y: 'bottom'})
                    return
                }
                
                this.$store.state.loading = true
                this.$store
                    .dispatch('address_to_geofence', this.address)
                    .then(res => {
                        if (res.data.success || (res.data.address && res.data.coordinates)) {
                            this.pointPrice = 'не определена'
                            if (res.data.geofence) {
                                const geofence = res.data.geofence
                                console.log('geofence', geofence)

                                if (!geofence.name) {
                                    geofence.name = 'Без имени'
                                }

                                let target

                                this.map.eachLayer(function(layer) {
                                    if (layer.properties && layer.properties.geofence && layer.properties.geofence.id == geofence.id) {
                                        target = layer
                                    }
                                })

                                // this.mapViewFlag = true
                                // this.mapViewPos = [+res.data.coordinates[1], +res.data.coordinates[0]]

                                this.map.flyToBounds(target.getBounds())


                                if (this.geofenceSelected && this.geofenceSelected != target) {
                                    this.modeDeselect()
                                }
                                this.geofenceSelected = target
                                if (!this.userIsPoint) {
                                    target.pm.enable()
                                }
                                target.setStyle(this.styleSelect)

                                this.geofence = target.properties.geofence
                                console.log('this.geofenceSelected', this.geofenceSelected)

                                if (this.pointPriceIndex != -1) {
                                    this.pointPrice = this.geofence.price[this.pointPriceIndex] + ' ₽'
                                } else {
                                    this.pointPrice = 'не определена'
                                }
                                if (this.userIsPoint) {
                                    this.$notify.toast(`Стоимость доставки: ${this.pointPrice}`, {y: 'bottom', color: 'primary'})
                                }
                            } else {
                                if (this.geofenceSelected) {
                                    this.modeDeselect()
                                }
                                this.map.flyToBounds([[+res.data.coordinates[1], +res.data.coordinates[0]]], {maxZoom: 16, padding: [80, 80]})
                                this.$notify.toast(`Стоимость доставки не определена - геозона не найдена.`, {y: 'bottom', color: 'error'})
                            }

                            // for (const layer of this.iconsData) {
                            //     this.map.removeLayer(layer)
                            // }

                            this.iconsData = []

                            this.iconsData.push({
                                color: '#0D47A1',
                                content: this.userIsPoint ? this.address + ' - ' + this.pointPrice : this.address,
                                lat: +res.data.coordinates[1],
                                lon: +res.data.coordinates[0],
                                options: {
                                    data: null,
                                },
                            })
                            console.log('this.iconsData', this.iconsData)
                            // Надеждинский район, п. Соловей ключ, ул. Кедровая, д. 12

                        } else {
                            this.$notify.toast(`Стоимость доставки не определена - адрес не найден.`, {y: 'bottom', color: 'error'})
                            
                            // console.log('res.data', res.data)

                        }
                        this.$store.state.loading = false


                    })
                    .catch(err => {
                        console.log(err)
                        this.$store.state.loading = false
                        this.$notify.toast(`Ошибка загрузки. Попробуйте снова.`, {y: 'bottom', color: 'error'})
                    })
            },
            onClickSave() {
                if (this.userIsPoint) {
                    return
                }

                console.log('onClickSave')
                // if (this.timer) clearInterval(this.timer)
                this.geofenceSaveTimer = 0
                this.needSave = false
                this.$store.commit('needSave', this.needSave)

                this.geofences.date = moment().format('YYYY-MM-DD HH:mm:ss')

                console.log('geofences0', this.geofences)
                this.$store
                    .dispatch('geofences_set', this.geofences)
                    .then(res => {
                        if (res.data.success) {
                            console.log('geofences save ok')
                        } else {
                            console.log('geofences save failure')
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        // this.$store.dispatch('logout').then(() => this.$router.push({ name: 'login' }))
                    })
            },
            onLogout() {
                this.$store.dispatch('logout').then(() => this.$router.push({ name: 'login' }))
            },
            geofenceColorsUpdate() {
                this.colors = this.generateHslColors(cfg.geofence.color.saturation, cfg.geofence.color.lightness, cfg.geofence.color.count)
            },
            geofenceSave() {
                this.geofenceSaved = false
                this.geofenceSaveTimer = cfg.geofence.savetime
                console.log('timer start for saving')
            },
            onNameSelect() {
                this.geofenceSelected.properties.geofence.name = this.geofence.name
                this.geofenceSave()
            },
            onColorSelect(color) {
                console.log('onColorSelect', color)
                console.log('this.geofenceSelected', this.geofenceSelected)
                this.geofence.color = color

                const colorCode = this.colors[this.geofence.color]

                this.geofenceSelected.setStyle({
                    fillColor: colorCode,
                })
                this.geofenceSelected.properties.geofence.color = this.geofence.color
                this.geofenceSave()
            },
            onNotifySelect() {
                this.geofenceSelected.properties.geofence.notify = this.geofence.notify
                this.geofenceSave()
            },
            onPriceSelect() {
                this.geofenceSelected.properties.geofence.price = this.geofence.price
                this.geofenceSave()
            },
            onDescrSelect() {
                this.geofenceSelected.properties.geofence.descr = this.geofence.descr
                this.geofenceSave()
            },
            initData() {
                let center = [43.121284, 131.901917]
                let zoom = 12
                if (this.$store.state.settings.city) {
                    if (this.$store.state.settings.city.includes('Екатеринбург')) {
                        center = [56.839104, 60.60825]
                    } else if (this.$store.state.settings.city.includes('Хабаровск')) {
                        center = [48.482369, 135.084641]
                    }
                }
                this.map = L.map('map', {
                    center,
                    zoom,
                    zoomControl: false,
                    doubleClickZoom: false,
                    attributionControl: false,
                })
                // L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(this.map)
                L.tileLayer(config.map.url, {
                    // attributionControl: false,
                    // trackResize: false,
                    // tapTolerance: 5,
                    maxZoom: 20,
                    minZoom: 6,
                    retina: '@2x',
                    detectRetina: true,
                }).addTo(this.map)

                this.geofenceColorsUpdate()
                this.colorWidth = 240 / cfg.geofence.color.count

                if (!this.userIsPoint) {
                    // set global options
                    this.map.pm.setGlobalOptions({
                        allowSelfIntersection: true,
                        snappable: true,
                    })

                    // set language
                    this.map.pm.setLang('ru')


                    // add controls
                    this.map.pm.addControls({
                        position: 'topleft',
                        drawPolygon: true,
                        drawMarker: false,
                        drawCircleMarker: false,
                        drawPolyline: false,
                        drawRectangle: true,
                        drawCircle: false,
                        editMode: true,
                        dragMode: true,
                        cutPolygon: false,
                        removalMode: true,
                    })

                    // set path style
                    this.map.pm.setPathOptions(this.styleInit)
                }

                console.log('get geofences')

                const local_geofences = this.$store.state.geofences
                let server_geofences = {}

                this.$store.dispatch('geofences_get').then(res => {
                    console.log('response geofences')
                    if (res.data.success) {
                        server_geofences = JSON.parse(res.data.geofences.data)
                    }

                    if (!this.userIsPoint) {

                        console.log('local_geofences', local_geofences)
                        console.log('server_geofences', server_geofences)

                        console.log('server date, local date', server_geofences.date, local_geofences.date)
                        const date_server_is_last = !moment(local_geofences.date).isAfter(server_geofences.date)
                        console.log('date_server_is_last', date_server_is_last)

                        if (!local_geofences.date || (server_geofences && date_server_is_last)) {
                            this.geofences = server_geofences
                            console.log('geofences from server')
                        } else if (local_geofences.date) {
                            this.geofences = local_geofences
                            console.log('geofences from local')
                            this.needSave = true
                            this.$store.commit('needSave', this.needSave)
                        } else {
                            this.geofences = {}
                            console.log('geofences empty')
                        }
                    } else {
                        this.geofences = server_geofences
                    }

                    console.log('geofences', this.geofences)

                    this.importGeoJSON(this.geofences)
                })

                this.map.on('pm:drawstart', e => {
                    console.log('⚡️ drawstart')
                    this.geofenceDraw = true
                })

                this.map.on('pm:drawend', e => {
                    console.log('⚡️ drawend')
                    this.geofenceDraw = false
                })

                this.map.on('pm:snap', e => {
                    console.log('⚡️ pm:snap')
                })

                this.map.on('pm:unsnap', e => {
                    console.log('⚡️ pm:unsnap')
                })

                this.map.on('pm:globaldrawmodetoggled', e => {
                    console.log('⚡️ pm:globaldrawmodetoggled')
                    if (this.geofenceSelected) {
                        this.geofenceSelected.setStyle(this.styleInit)
                        this.geofenceSelected.pm.disable()
                        this.geofenceSelected = null
                    }
                })

                this.map.on('pm:globaleditmodetoggled', e => {
                    console.log('⚡️ pm:globaleditmodetoggled')
                    this.modeDeselect()
                })

                this.map.on('pm:globaldragmodetoggled', e => {
                    console.log('⚡️ pm:globaldragmodetoggled')
                    this.modeDeselect()
                })

                this.map.on('pm:globalremovalmodetoggled', e => {
                    console.log('⚡️ pm:globalremovalmodetoggled')
                    this.modeDeselect()
                })

                this.map.on('pm:create', e => {
                    console.log('⚡️ pm:create e', e)
                    let color
                    if (cfg.geofence.color.random) {
                        color = this.getRUIterator(cfg.geofence.color.count).generate()
                    } else {
                        color = cfg.geofence.color.index
                    }
                    const color_hsl = this.colors[color]

                    e.layer.setStyle({
                        fillColor: color_hsl,
                    })
                    e.layer.properties = {}
                    e.layer.properties.geofence = {
                        id: uuidv4(),
                        color,
                        name: cfg.geofence.name,
                        notify: cfg.geofence.notify,
                        price: cfg.geofence.price.slice()
                    }

                    this.layerAddEvents(e.layer)
                })
                

                this.map.on('moveend zoomend', e => {
                    console.log('⚡️ moveend zoomend map')
                    if (this.mapViewFlag) {
                        this.mapViewFlag = false
                        this.map.setView(this.mapViewPos)
                    }
                })

                
                // let point_step = 0
                // let point1, point2


                this.map.on('click', e => {
                    console.log('⚡️ click map 1', e)
                    // point_step++
                    // if (point_step == 1) {
                    //     point1 = [e.latlng.lng, e.latlng.lat]
                    //     console.log('point1', point1)
                    // } else {
                    //     point2 = [e.latlng.lng, e.latlng.lat]
                    //     point_step = 0
                    //     console.log('point2', point2)


                    //     this.$http
                    //         .get(config.api.delivery + 'distance', { params: {point1, point2} })
                    //         .then(res => {

                    //             console.log('distance', res.data.distance)
                    //         })
                    //         .catch(err => {
                    //             console.log(err)
                    //         })


                    // }
                    if (!this.map.pm.globalEditModeEnabled() && !this.map.pm.globalDragModeEnabled()) {
                        console.log('⚡️ click map 2')
                        if (!this.geofenceOver && this.geofenceSelected && !this.markerDrag) {
                            console.log('⚡️ click map 3')
                            this.modeDeselect()
                        }
                    }
                    console.log('⚡️ click map 4')
                })

                this.map.on('mouseup', e => {
                    console.log('⚡️ mouseup map')
                })

                this.map.on('move', e => {
                    console.log('⚡️ move map')
                })

                this.map.on('keydown', e => {
                    console.log(e)
                    if (e.originalEvent.code == 'Delete') {
                        if (this.geofenceSelected) {
                            this.map.removeLayer(this.geofenceSelected)
                            this.geofenceSelected = null
                        }
                    } else if (e.originalEvent.code == 'Escape') {
                        if (this.geofenceSelected) {
                            const layer = this.geofenceSelected
                            this.modeDeselect()
                            if (this.geofenceOver) {
                                layer.setStyle(this.styleHighlight)
                            }
                        }
                        this.map.pm.disableDraw()
                    }
                    this.mapUpdated(e)
                })

                // listen to events
                this.map.on('pm:create', this.mapUpdated)
                this.map.on('pm:remove', this.mapUpdated)
            },
            layerAddEvents(layer) {
                layer.on('mouseover', e => {
                    if (this.geofenceSelected != e.target) {
                        console.log('⚡️ mouseover')
                        console.log('this.styleHighlight', this.styleHighlight)
                        console.log('e.target', e.target)
                        e.target.setStyle(this.styleHighlight)
                        e.target.bringToFront()
                    }
                    this.geofenceOver = true
                })

                layer.on('mouseout', e => {
                    console.log('⚡️ mouseout')
                    if (this.geofenceSelected != e.target) {
                        e.target.setStyle(this.styleInit)
                    }
                    this.geofenceOver = false
                })

                layer.on('pm:dragstart', e => {
                    console.log('⚡️ pm:dragstart')
                    this.geofenceDrag = true
                })

                layer.on('pm:dragend', e => {
                    console.log('⚡️ pm:dragend')
                    this.geofenceDrag = false
                })

                layer.on('pm:markerdragstart', e => {
                    console.log('⚡️ pm:markerdragstart')
                    this.markerDrag = true
                    console.log('this.markerDrag', this.markerDrag)
                })

                layer.on('pm:markerdragend', e => {
                    console.log('⚡️ pm:markerdragend')

                    setTimeout(() => {
                        this.markerDrag = false
                        console.log('this.markerDrag', this.markerDrag)
                    }, 100)
                })

                layer.on('pm:update', e => {
                    console.log('⚡️ pm:update')
                })

                layer.on('pm:drag', e => {
                    if (this.geofenceSelected) {
                        if (!this.userIsPoint) {
                            this.geofenceSelected.pm.enable()
                        }
                    }
                })


                layer.on('click', event => {
                    console.log('click', event)
                    if (!this.map.pm.globalEditModeEnabled() && !this.map.pm.globalDragModeEnabled()) {
                        if (!this.geofenceDrag && !this.geofenceDraw) {
                            if (this.geofenceSelected && this.geofenceSelected != event.target) {
                                this.modeDeselect()
                            }
                            this.geofenceSelected = event.target
                            if (!this.userIsPoint) {
                                event.target.pm.enable()
                            }
                            event.target.setStyle(this.styleSelect)
                        }
                    }
                    this.geofence = event.target.properties.geofence
                    console.log('this.geofenceSelected', this.geofenceSelected)

                    if (this.pointPriceIndex != -1) {
                        this.pointPrice = this.geofence.price[this.pointPriceIndex]
                    } else {
                        this.pointPrice = 'не установлена'
                    }
                    
                    
                })
            },
            mapUpdated(event) {
                console.log('mapUpdated before', this.geofence)
                // add listeners on creation and delete on removal
                if (event.type === 'pm:create') {
                    // add data
                    event.layer.properties.shape = event.shape

                    // event.layer.properties = {
                    //     shape: event.shape,
                    // }

                    // radius for circles
                    if (event.shape === 'Circle') {
                        event.layer.properties.radius = event.layer.getRadius()
                    }
                }
                if (event.type === 'pm:remove') {
                    event.layer.off() // remove all event listeners
                }

                // emit event
                // this.$emit('change', this.getDataAsGeoJSON())

                this.geofences = this.getDataAsGeoJSON()
                console.log('mapUpdated after', this.geofence)
            },

            // export data as GeoJSON object
            getDataAsGeoJSON() {
                // create FeatureCollection
                const geoJSON = {
                    type: 'FeatureCollection',
                    features: [],
                }

                    console.log('getDataAsGeoJSON map', this.map)
                // export each layer
                this.map.eachLayer(function(layer) {
                    console.log('getDataAsGeoJSON layer', layer)
                    if (layer.properties && layer.properties.geofence && (layer instanceof L.Path || layer instanceof L.Marker)) {
                        const geoJSONShape = layer.toGeoJSON(16) // to precise geo shape!
                        geoJSONShape.properties = layer.properties
                        geoJSONShape.properties.options = layer.options
                        // geoJSONShape.properties.geofence = layer.geofence
                        // geoJSONShape.options = layer.options
                        // geoJSONShape.geofence = layer.geofence
                        geoJSON.features.push(geoJSONShape)

                        // normalize coordinates (> 180/>90)
                        // TODO
                    }
                })
                geoJSON.date = moment().format('YYYY-MM-DD HH:mm:ss')

                if (!this.userIsPoint) {

                    this.$store.commit('geofences', geoJSON)
                    this.needSave = true
                    this.$store.commit('needSave', this.needSave)
                }

                return geoJSON
            },

            // import data from GeoJSON
            importGeoJSON(geoJSON) {
                // console.log('geoJSON', JSON.stringify(geoJSON))
                if (geoJSON && geoJSON.type === 'FeatureCollection' && geoJSON.features && geoJSON.features.length) {
                    geoJSON.features.forEach(feature => {
                        const shape = feature.properties && feature.properties.shape
                        const coordinates = feature.geometry && feature.geometry.coordinates
                        console.log('feature.properties', feature.properties)
                        // const options = Object.assign({}, feature.properties.options)
                        let layer // define shape for later use
                        switch (shape) {
                            case 'Circle':
                                layer = new L.Circle([coordinates[1], coordinates[0]], feature.properties.radius)
                                break
                            case 'CircleMarker':
                                layer = new L.CircleMarker([coordinates[1], coordinates[0]])
                                break
                            case 'Marker':
                                layer = new L.Marker([coordinates[1], coordinates[0]])
                                break
                            case 'Polyline':
                                layer = new L.Polyline(this.switchCoordinates(coordinates))
                                break
                            case 'Polygon':
                                layer = new L.Polygon(this.switchCoordinates(coordinates), feature.properties.options)
                                break
                            case 'Rectangle':
                                layer = new L.Rectangle(this.switchCoordinates(coordinates), feature.properties.options)
                                break
                        }


                        if (layer) {
                            // this.map.pm.setPathOptions(this.styleInit)
                            layer.setStyle({
                                weight: 1,
                            })
                            layer.addTo(this.map)
                            layer.properties = feature.properties
                            // layer.options = feature.properties.options
                            // layer.geofence = feature.properties.geofence

                            console.log('layer', layer)

                            // add event listener
                            layer.on('pm:edit', this.mapUpdated)

                            this.layerAddEvents(layer)
                        }
                    })
                }
            },
            // switch coordinates -> geoJSON to Leaflet
            switchCoordinates(coordinates) {
                return [coordinates[0].map(pair => [pair[1], pair[0]])]
            },
            generateHslColors(saturation, lightness, amount) {
                let colors = []
                let huedelta = Math.trunc(360 / amount)
                for (let i = 0; i < amount; i++) {
                    let hue = i * huedelta
                    colors.push(`hsl(${hue},${saturation}%,${lightness}%)`)
                }
                return colors
            },
            getRUIterator(max) {
                // Итератор случайных уникальных - Random Unique
                let buffer = []
                const ruIterator = {
                    generate: function() {
                        if (buffer.length === 0) {
                            // если колода пуста
                            for (let i = 0; i < max; i++) buffer.push(i) // открываем новую 0..max
                        }
                        const index = Math.floor(Math.random() * buffer.length)
                        return buffer.splice(index, 1)[0] // вынимаем из колоды случайную карту
                    },
                }
                return ruIterator
            },
            modeDeselect() {
                console.log('⚡️ modeDeselect')
                console.log('this.geofenceSelected', this.geofenceSelected)
                if (this.geofenceSelected) {
                    this.geofenceSelected.setStyle(this.styleInit)
                    this.geofenceSelected.pm.disable()
                    this.geofenceSelected = null
                }
            },
        },
    }
</script>

<style lang="stylus">
    .geofences
        // height 100%

        a
            text-decoration none

        #map
            width 100%
            // height 100%
            z-index 1

        .map-wrapper
            width 100%
            height 100%
            position absolute
            display flex

        .banner
            background-color white !important
            position fixed !important
            top 130px
            right 20px
            width 350px
            z-index 1

        .colors_wrapper
            display inline-flex
            line-height 30px

            .header
                width 50px

            .color
                height 30px

                &.selected
                    border 2px solid white !important

                &:hover
                    border 2px solid white
                    cursor pointer

        .button-close
            position absolute
            right 10px
            top 10px
            min-height 30px !important
            min-width 30px !important
            margin 0 !important
            padding 0 !important

        .v-alert
            width 400px
            position absolute
            display flex
            bottom 2px
            left 90px
            z-index 2

        .search
            width 80%
            max-width 480px
            position absolute
            display flex
            top 10px
            right 10px
            z-index 2
            // background-color rgba(255,255,255,.5)
            // margin-left 10px

            .search-button
                height 40px
                // height 32px
            
            input
                margin-top 5px !important
                font-size 20px
            label
                top 12px

            .v-input__control
                background-color rgba(255,255,255,.5)

            .v-input__slot
                min-height 40px

            // .v-input .v-label
            //     line-height 20px

            .v-label--active
                // line-height 10px
                top 18px

            // input:-webkit-autofill,
            // input:-webkit-autofill:hover,
            // input:-webkit-autofill:focus,
            // input:-webkit-autofill:active {
            //     -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
            //     -webkit-transition-delay: 9999s;
            // }

            input:-webkit-autofill { 
                -webkit-background-clip: text;
            }

</style>
